<script>
import { PREPRINTS as preprints } from '@/config'
import { fetchStats } from '@/api'
import convertNum from '@/helpers/number'
import StaticPageLayout from '@/templates/static/StaticPageLayout'

// the style of the count graph from just copied from the Wicket page
export default {
  metaInfo() {
    return {
      title: 'Help - Europe PMC',
    }
  },
  components: { StaticPageLayout },
  data() {
    return {
      title: 'Help using Europe PMC',
      sections: [
        {
          id: 'searchtips',
          heading: 'h2',
          title: 'Search tips',
          collapsible: true,
          visible: true,
          highlight: true,
          scroll: true,
          children: [
            {
              id: 'searchEPMC',
              title: 'How to use the search',
              highlight: false,
            },
            {
              id: 'topicsearch',
              title: 'Topic search',
              highlight: false,
            },
            {
              id: 'searchspecific',
              title: 'Find a specific article',
              highlight: false,
              showChildren: false,
              children: [
                {
                  id: 'searchbytitle',
                  title: 'Title search',
                  highlight: false,
                },
                {
                  id: 'searchbyauthor',
                  title: 'searchbyauthor',
                  highlight: false,
                },
                {
                  id: 'searchbyID',
                  title: 'DOI, PMID or PMCID search',
                  highlight: false,
                },
              ],
            },
            {
              id: 'searchjournaltitle',
              title: 'Journal search',
              highlight: false,
            },
            {
              id: 'searchdata',
              title: 'Data citations or links search',
              highlight: false,
            },
            {
              id: 'searchbygrant',
              title: 'Grant number or agency search',
              highlight: false,
            },
          ],
        },
        {
          id: 'managesearch',
          heading: 'h2',
          title: 'Manage your search results',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
          children: [
            {
              id: 'sortmyresults',
              title: 'Sort search results',
              highlight: false,
              children: [
                {
                  id: 'relevance',
                  title: 'How relevance is calculated',
                  highlight: false,
                },
              ],
            },
            {
              id: 'filters',
              title: 'Filter search results',
              highlight: false,
              children: [
                {
                  id: 'searchcoverFT',
                  title: 'Free full text access',
                  highlight: false,
                },
                {
                  id: 'limitbycontent',
                  title: 'Type',
                  highlight: false,
                },
                {
                  id: 'pubdatefilter',
                  title: 'Publication date',
                  highlight: false,
                },
              ],
            },
            {
              id: 'getFTA',
              title: 'Find the full text of an article',
              highlight: false,
            },
            {
              id: 'citearticles',
              title: 'How to cite an article',
              highlight: false,
              children: [
                {
                  id: 'exportarticle',
                  title: 'To export a single record',
                  highlight: false,
                },
                {
                  id: 'exportmysearch',
                  title: 'To export a selection of records from a search',
                  highlight: false,
                },
              ],
            },
            {
              id: 'saveSearch',
              title: 'Save search & create alert',
              highlight: false,
              children: [
                {
                  id: 'sign-in-options',
                  title: 'Sign in options',
                  highlight: false,
                },
                {
                  id: 'save-the-query',
                  title: 'Save the query and create an alert',
                  highlight: false,
                },
                {
                  id: 'edit-query',
                  title: 'Edit query and test results',
                  highlight: false,
                },
                {
                  id: 'choose-whether-to-update',
                  title: 'Choose whether and when to receive email updates',
                  highlight: false,
                },
                {
                  id: 'add-or-edit-email',
                  title: 'Add or edit your alerts email',
                  highlight: false,
                },
                {
                  id: 'select-content',
                  title: "Select the content you'd like to receive",
                  highlight: false,
                },
                {
                  id: 'save-alert',
                  title: 'Save the alert',
                  highlight: false,
                },
              ],
            },
          ],
        },
        {
          id: 'mostofsearch',
          heading: 'h2',
          title: 'Advanced search tips',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
          children: [
            {
              id: 'exactmatch',
              title: 'Exact match searches',
              highlight: false,
            },
            {
              id: 'booleans',
              title: 'Boolean operators',
              highlight: false,
            },
            {
              id: 'wildcards',
              title: 'Wildcard operators',
              highlight: false,
            },
            {
              id: 'advancedform',
              title: 'Advanced search form',
              highlight: false,
              children: [
                {
                  id: 'searchmetadata',
                  title: 'Search within article metadata',
                  highlight: false,
                },
                {
                  id: 'searchselsections',
                  title: 'Search within an article section',
                  highlight: false,
                },
                {
                  id: 'searchusingmesh',
                  title: 'MeSH terms and synonyms',
                  highlight: false,
                },
              ],
            },
            {
              id: 'searchsyntax',
              title: 'Search syntax',
              highlight: false,
            },
          ],
        },
        {
          id: 'epmccontent',
          heading: 'h2',
          title: 'Content in Europe PMC',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
          children: [
            {
              id: 'contenttypes',
              title: 'Types of content',
              highlight: false,
            },
            {
              id: 'absFTAdiff',
              title: 'Differences between abstracts and full text articles',
              highlight: false,
            },
            {
              id: 'preprints-section',
              title: 'Preprints',
              highlight: false,
            },
            {
              id: 'sources',
              title: 'Differences between PubMed, PMC and Europe PMC',
              highlight: false,
            },
            {
              id: 'authmanuscripts',
              title: 'Author manuscripts',
              highlight: false,
              children: [
                {
                  id: 'authmandiff',
                  title:
                    'Differences between author manuscripts and journal articles',
                  highlight: false,
                },
              ],
            },
            {
              id: 'journalsinEPMC',
              title: 'Journals',
              highlight: false,
            },
            {
              id: 'otherlanguages',
              title: 'Non-English articles',
              highlight: false,
            },
            {
              id: 'restrictions',
              title: 'Copyright restrictions on the use of material',
              highlight: false,
            },
            {
              id: 'howORCIDsused',
              title: 'ORCIDs',
              highlight: false,
              children: [
                {
                  id: 'orcidsforreaders',
                  title: 'Benefits for readers',
                  highlight: false,
                },
                {
                  id: 'orcidsforauthors',
                  title: 'Benefits for authors',
                  highlight: false,
                },
              ],
            },
            {
              id: 'impact',
              title: 'Citations & Impact',
              highlight: false,
              children: [
                {
                  id: 'citationsnetwork',
                  title: 'About the Europe PMC citations network',
                  highlight: false,
                },
                {
                  id: 'whyEpmcHasFewer',
                  title:
                    'Why are Europe PMC citation counts different to Google Scholar, Web of Science or Scopus?',
                  highlight: false,
                },
                {
                  id: 'citationsincomplete',
                  title:
                    'Why is the citation information for some articles incomplete?',
                  highlight: false,
                },
              ],
            },
            {
              id: 'contentsources',
              title: 'Sources of content in Europe PMC',
              highlight: false,
            },
            {
              id: 'howcontentadded',
              title: 'How content is added to Europe PMC',
              highlight: false,
              children: [
                {
                  id: 'howjournals',
                  title: 'Journals and books',
                  highlight: false,
                },
                {
                  id: 'howpreprints',
                  title: 'Preprints',
                  highlight: false,
                },
                {
                  id: 'howmanuscripts',
                  title: 'Author manuscripts',
                  highlight: false,
                },
                {
                  id: 'howgrants',
                  title: 'Grant data',
                  highlight: false,
                },
                {
                  id: 'howpatents',
                  title: 'Patents',
                  highlight: false,
                },
                {
                  id: 'howorcids',
                  title: 'ORCIDs',
                  highlight: false,
                },
                {
                  id: 'howannotations',
                  title: 'Annotations',
                  highlight: false,
                },
                {
                  id: 'howcitations',
                  title: 'Citations',
                  highlight: false,
                },
              ],
            },
          ],
        },
        {
          id: 'linkeddata',
          heading: 'h2',
          title: 'Linked data and resources',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
          children: [
            {
              id: 'figures',
              title: 'Figures',
              highlight: false,
            },
            {
              id: 'datalinks',
              title: 'Data',
              highlight: false,
            },
            {
              id: 'reviews',
              title: 'Reviews & evaluations',
              highlight: false,
              children: [
                {
                  id: 'notifications-of-reviews-and-evaluations',
                  title: 'Notification of Reviews & evaluations',
                  highlight: false,
                },
                {
                  id: 'reviews-and-evaluation-section',
                  title: 'The Reviews & evaluations section',
                  highlight: false,
                },
              ],
            },
            {
              id: 'protocols',
              title: 'Protocols & materials',
              highlight: false,
            },
            {
              id: 'summaries',
              title: 'Lay summaries',
              highlight: false,
            },
            {
              id: 'similararts',
              title: 'Similar articles',
              highlight: false,
            },
            {
              id: 'funding',
              title: 'Funding',
              highlight: false,
            },
            {
              id: 'keyterms',
              title: 'Key terms',
              highlight: false,
              children: [
                {
                  id: 'reportterms',
                  title: 'Report incorrect annotations',
                  highlight: false,
                },
                {
                  id: 'whyterms',
                  title:
                    'Why do Key terms not appear on every full-text article in Europe PMC?',
                  highlight: false,
                },
                {
                  id: 'participate',
                  title: 'Participate in the Europe PMC external links service',
                  highlight: false,
                },
              ],
            },
          ],
        },
        {
          id: 'authorservices',
          heading: 'h2',
          title: 'Author services',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
          children: [
            {
              id: 'linktoORCID',
              title: 'Link articles to your ORCID',
              highlight: false,
            },
            {
              id: 'authorprofile',
              title: 'Author profiles',
              highlight: false,
              children: [
                {
                  id: 'whynopublications',
                  title:
                    'Why are there no publications displayed on my author profile?',
                  highlight: false,
                },
                {
                  id: 'preventpublications',
                  title:
                    'Prevent publications from being displayed on your author profile',
                  highlight: false,
                },
                {
                  id: 'accessprofile',
                  title: "Accessing another author's profile",
                  highlight: false,
                },
              ],
            },
            {
              id: 'submitmanuscript',
              title: 'Submit a manuscript',
              highlight: false,
            },
          ],
        },
        {
          id: 'readingfeatures',
          heading: 'h2',
          title: 'Features for readers',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
          children: [
            {
              id: 'accessSavedAlerts',
              title: 'Saved alerts',
              highlight: false,
              children: [
                {
                  id: 'finding-alerts',
                  title: 'Finding saved alerts',
                  highlight: false,
                },
                {
                  id: 'finding-searches',
                  title: 'Finding saved searches',
                  highlight: false,
                },
                {
                  id: 'what-to-do-from-saved-alerts',
                  title: 'What you can do from the Saved alerts page',
                  highlight: false,
                },
              ],
            },
            {
              id: 'epmcaccounts',
              title: 'Europe PMC Accounts',
              highlight: false,
              children: [
                {
                  id: 'signinEPMCAccount',
                  title: 'How to sign into Europe PMC',
                  highlight: false,
                },
                {
                  id: 'createEPMCAccount',
                  title: 'Create a Europe PMC account',
                  highlight: false,
                },
                {
                  id: 'activateEPMCAccount',
                  title:
                    "I have created a Europe PMC account but I can't access my saved searches",
                  highlight: false,
                },
                {
                  id: 'deleteAccount',
                  title: 'How to delete your user account',
                  highlight: false,
                },
              ],
            },
          ],
        },
      ],
      counts: {
        patent: {
          query: 'SRC:PAT',
          count: '',
        },
        fulltext: {
          query: 'HAS_FT:Y',
          count: '',
        },
        abstract: {
          query: 'SRC:*',
          count: '',
        },
        pubmed: {
          query: 'SRC:MED',
          count: '',
        },
        nhs: {
          query:
            'PUBLISHER:"nice" OR PUBLISHER:"national institute for health and clinical excellence" OR PUBLISHER:"national institute for health and care excellence"',
          count: '',
        },
        agricola: {
          query: 'SRC:AGR',
          count: '',
        },
        preprint: {
          query: 'SRC:PPR',
          count: '',
        },
        openaccess: {
          query: 'OPEN_ACCESS:Y',
          count: '',
        },
      },
      preprints,
    }
  },
  created() {
    const {
      patent,
      fulltext,
      abstract,
      pubmed,
      nhs,
      agricola,
      preprint,
      openaccess,
    } = this.counts

    fetchStats(patent.query).then((response) => {
      patent.count = convertNum(response.hitCount)
    })
    fetchStats(fulltext.query).then((response) => {
      fulltext.count = convertNum(response.hitCount)
    })
    fetchStats(abstract.query).then((response) => {
      abstract.count = convertNum(response.hitCount)
    })
    fetchStats(pubmed.query).then((response) => {
      pubmed.count = convertNum(response.hitCount)
    })
    fetchStats(nhs.query).then((response) => {
      nhs.count = convertNum(response.hitCount)
    })
    fetchStats(agricola.query).then((response) => {
      agricola.count = convertNum(response.hitCount)
    })
    fetchStats(preprint.query).then((response) => {
      preprint.count = convertNum(response.hitCount)
    })
    fetchStats(openaccess.query).then((response) => {
      openaccess.count = convertNum(response.hitCount)
    })
  },
}
</script>
<template>
  <div id="search-syntax-page">
    <static-page-layout :title="title" :sections="sections">
      <template slot-scope="{ section }">
        <template v-if="section.id === 'searchtips'">
          <h3 id="searchEPMC">How to use the search</h3>
          <p>
            The main Europe PMC site provides two search functions: a free text
            search, direct from the main search box, and an
            <a href="/advancesearch">Advanced search</a> which provides you with
            a number of different options.
          </p>
          <p>
            You can refine your search by entering search syntax in the search
            box. The
            <a href="/searchsyntax">search syntax reference</a> includes many
            examples.
          </p>
          <p>
            If you misspell a query term, for example 'luekemia' rather than
            'leukemia', a "did you mean" suggestion for your search is provided.
          </p>

          <h3 id="topicsearch">Topic search</h3>
          <p>
            To search for a topic, enter your search terms directly into the
            main search box. Consider using an
            <a href="#exactmatch">exact match search</a> to narrow your results.
            You can also
            <a href="#searchselsections"
              >search within specific sections of the article</a
            >
            using the Advanced search. Use
            <a href="#filters">search filters</a> on the search results page to
            narrow your search.
          </p>

          <h3 id="searchspecific">Find a specific article</h3>
          <h4 id="searchbytitle">Title search</h4>
          <p>
            If an exact title is known, and it is fairly distinct, try placing
            the full title in quotation marks to find exact matches:
          </p>
          <p>
            "Leukotriene E4 activates human Th2 cells for exaggerated
            proinflammatory cytokine production in response to prostaglandin D2"
          </p>
          <p>
            If only one article is found, the search will go directly to the
            article page.
          </p>
          <p>
            You can also use the <a href="/advancesearch">Advanced search</a> to
            search only within the title field.
          </p>
          <h4 id="searchbyauthor">Author or ORCID search</h4>
          <p>
            In the main search box you can search for works by authors as
            follows:
          </p>
          <ul>
            <li>
              First name and last name, for example:
              <a href="/search?page=1&amp;query=John+Smith&amp;sortby=Relevance"
                >'John Smith'</a
              >
            </li>
            <li>
              Last name and first name, for example:
              <a href="/search?page=1&amp;query=Smith+John&amp;sortby=Relevance"
                >'Smith John'</a
              >
            </li>
            <li>
              Last name and initials, for example:
              <a href="/search?page=1&amp;query=Smith+J&amp;sortby=Relevance"
                >'Smith J',</a
              >
              or
              <a href="/search?page=1&amp;query=Smith+JJ&amp;sortby=Relevance"
                >'Smith JJ'</a
              >
            </li>
            <li>
              Initials and last name, for example
              <a href="/search?query=J%20Smith">'J Smith'</a>
            </li>
          </ul>
          <p>
            When you enter an author name in the main search box a matching
            process restricts your search to the author metadata field using the
            'AUTH' <a href="#searchsyntax">search syntax</a>. Up to two
            suggested authors, who have a matching surname and initial or first
            name, may be shown at the top of the search results, as shown below.
            Suggested authors have linked their articles in Europe PMC to their
            <a href="#howORCIDsused">ORCID iD</a>. The two authors with the most
            publications are shown. You can restrict your search to a suggested
            author's publications using the link from the author name, which
            will search Europe PMC using their ORCID iD e.g.
            <a
              href="/search?page=1&amp;query=AUTHORID:%220000-0002-6982-4660%22"
              >AUTHORID:"0000-0002-6982-4660"</a
            >.
          </p>
          <div>
            <img
              src="@/assets/SuggestedAuthors.png"
              alt="Search page author suggestion"
            />
          </div>
          <p>
            The author search field on the Advanced search page suggests author
            name matches as you type. If an author has an ORCID iD the
            auto-suggest will also display it as shown below:
          </p>
          <div>
            <img
              src="@/assets/AuthorNameSearch.png"
              alt="Author name search demonstrating an author and ORCID suggestion"
              class="medium"
            />
          </div>
          <p>
            The list of names presented is ordered by the number of articles an
            author has published. Alternatively you can just enter an ORCID into
            the author field, and the author will be found if he/she has a
            linked article contained in Europe PMC. For example:
          </p>
          <div>
            <img
              src="@/assets/AuthorORCIDSearch.png"
              alt="Author ORCID search"
              class="medium"
            />
          </div>
          <p>
            An author's ORCID iD can also be typed directly into the quick
            search box, as opposed to using an author's name:
          </p>
          <div>
            <img src="@/assets/ORCIDSearching.png" alt="Searching by ORCID" />
          </div>
          <h4 id="searchbyID">DOI, PMID or PMCID search</h4>
          <p>
            To search by DOI (Digital Object Identifier) you can enter the DOI
            into the search box and this will usually find a result. To be more
            specific use the search syntax 'DOI:', for example:<br /><a
              href="/search?query=DOI:10.1007/bf00197367"
              >DOI:10.1007/bf00197367</a
            >
          </p>
          <p>
            Enter the PMID (PubMed ID, identifier for an abstract record) number
            into the search box and this will usually find a result.
            Alternatively use the search syntax 'EXT_ID:'. PMCIDs (PubMed
            Central IDs, identifiers for full text articles) can be entered into
            the search box, for example:<br /><a href="/search?query=PMC3013671"
              >PMC3013671</a
            >
          </p>
          <p>
            For a more specific search use the prefix PMCID, for example:<br /><a
              href="/search?query=PMCID:PMC3013671"
              >PMCID:PMC3013671</a
            >
          </p>
          <p>
            PMIDs are unique when used in conjunction with a data source. When
            searching for a PMID that yields more than one result, the data
            source can be specified to find the exact match, for example the
            following search returns three results:<br /><a
              href="/search?query=EXT_ID:526631"
              >EXT_ID:526631</a
            >
          </p>
          <p>
            Specifying the data source using the 'SRC:' search term, along with
            the PMID will find a unique result, for example:<br /><a
              href="/search?query=EXT_ID:526631%20AND%20SRC:MED"
              >EXT_ID:526631 AND SRC:MED</a
            >
          </p>

          <h3 id="searchjournaltitle">Journal search</h3>
          <p>
            When searching for a journal using its full name from the free text
            quick search box, the 'JOURNAL:' search syntax can be used, for
            example:
            <a
              href="/search?page=1&amp;query=JOURNAL:%22Advances+in+Pharmacological+Sciences%22"
              >JOURNAL:"Advances in Pharmacological Sciences"</a
            >
            to make the search more accurate.
          </p>
          <p>
            You can access the <a href="/journalList">Journal List</a> from
            'Tools' in the navigation bar. The list features titles that deposit
            full text content to Europe PMC and can also be used as a quick way
            of searching the contents of a particular journal. For bibliographic
            information about a Europe PMC journal, click on the ISSN alongside
            the title in the Journal List.
          </p>
          <p>
            You can search for journal titles directly from the
            <a href="/advancesearch">Advanced search</a> page by typing into the
            journal field – the autocomplete function will suggest likely
            matches that you can choose from.
          </p>

          <h3 id="searchdata">Data citations or links search</h3>
          <p>
            Data citations are mentions of accession numbers (unique identifiers
            for data) in the text of articles. Data citations are available for
            data in a number of different databases including:
            <a href="//www.ebi.ac.uk/ena/"
              >European Nucleotide Archive<i
                class="fas fa-external-link-alt"
              ></i></a
            >, <a href="//www.ebi.ac.uk/uniprot/">UniProt</a>,
            <a href="//www.ebi.ac.uk/pdbe/"
              >PDBe<i class="fas fa-external-link-alt"></i></a
            >,
            <a href="//www.ncbi.nlm.nih.gov/omim"
              >OMIM<i class="fas fa-external-link-alt"></i></a
            >,
            <a href="//www.ncbi.nlm.nih.gov/projects/SNP/"
              >RefSNP<i class="fas fa-external-link-alt"></i></a
            >,
            <a href="//www.ncbi.nlm.nih.gov/refseq/"
              >RefSeq<i class="fas fa-external-link-alt"></i></a
            >,
            <a href="//pfam.xfam.org/"
              >Pfam<i class="fas fa-external-link-alt"></i></a
            >,
            <a href="//www.ebi.ac.uk/interpro/"
              >InterPro<i class="fas fa-external-link-alt"></i></a
            >, and
            <a href="//www.ensembl.org/index.html"
              >Ensembl<i class="fas fa-external-link-alt"></i></a
            >. Data links are citations of an article by a database record. Data
            links from a specific data record to a Europe PMC article are
            provided by a number of life science databases. The databases
            included are listed in sections
            <a href="/searchsyntax#databasecrossreferences">2.7</a> and
            <a href="/searchsyntax#databasecitations">2.8</a> of the
            <a href="/searchsyntax">Search syntax reference</a>. The screenshot
            below shows the relevant section on the Advanced search form:
          </p>
          <div>
            <img
              src="@/assets/DataLinksAndCitations.png"
              alt="Options in data links menu"
              class="small"
            />
          </div>
          <p>
            You can also select any combination of links and citations using the
            Boolean operators available on the form.
          </p>
          <p>
            <a href="#datalinks">Supporting data</a> is linked from the article
            page.
          </p>
          <p>
            You can also find papers that cite data DOIs. For programmatic
            users, all searches can also be used in either the
            <a href="/SoapWebServices">SOAP</a> or
            <a href="/RestfulWebService">RESTful Web Services.</a>
          </p>

          <h3 id="searchbygrant">Grant number or agency search</h3>
          <p>
            Search for articles resulting from projects supported by specific
            funders, or individual grants, on the
            <a href="/advancesearch">Advanced search</a> page using the Funder
            or Grant ID fields—both of which are auto-suggest fields, under
            Funding Attribution:
          </p>
          <div>
            <img
              src="@/assets/FundingAttribution.png"
              alt="Screenshot of Advanced search page Funding Attribution section"
              class="medium"
            />
          </div>
          <p>
            Alternatively use search syntax in the main search box. For articles
            related to a specific grant, you should use 'GRANT_ID:' followed by
            the number which you are interested in, for example:<br /><a
              href="/search?query=GRANT_AGENCY%3A%22Wellcome%20Trust%22%20GRANT_ID%3A%22069683%22"
              >GRANT_AGENCY:"Wellcome Trust" GRANT_ID:"069683"</a
            >
          </p>
          <p>
            The <a href="/grantfinder">grant finder tool</a> enables you to find
            grant information for grants from
            <a href="/Funders">Europe PMC funders</a>.
          </p>
        </template>
        <template v-else-if="section.id === 'managesearch'">
          <h3 id="sortmyresults">Sort search results</h3>
          <p>Europe PMC results can be sorted as follows:</p>
          <ol>
            <li>By relevance</li>
            <li>By publication date</li>
            <li>By date received</li>
            <li>By times cited</li>
          </ol>
          <p>
            The sort options appear above the search results list, in a dropdown
            labeled "sort by" (see below). The sort applied to the search
            results list is highlighted, and defaults to 'Relevance'.
          </p>
          <div>
            <img
              src="@/assets/SortBy.png"
              alt="The sort by dropdown"
              class="medium"
            />
          </div>
          <p>
            If you want to see which articles are new in Europe PMC since your
            last search, sort by 'Date received'. This is the date when the
            article first appeared in Europe PMC. Whereas 'Date published' is
            the date the article was first published in a journal.
          </p>
          <p>
            The 'Times Cited (most)' sort shows the most highly cited articles,
            listed in descending order. The citation counts per paper in Europe
            PMC might differ from the counts displayed in Scopus or Web of
            Science, because Europe PMC does not have access to the same content
            as these resources. However, highly cited articles in Europe PMC
            correlate with highly cited papers on other platforms, and the
            information on where papers are being cited is freely available.
            Find out more
            <a href="#citationsnetwork">about the Europe PMC citation network</a
            >.
          </p>
          <h4 id="relevance">How relevance is calculated</h4>
          <p>
            Europe PMC displays the most relevant results for the search
            executed, according to document scoring and weighting. The document
            with the highest score is displayed first, followed by second and
            third highest scoring documents and so on.
          </p>
          <p>
            The score each document receives for any given search is based on a
            number of factors including:
          </p>
          <ul>
            <li>
              The frequency with which a term appears in a document. Given a
              search query, the higher the term frequency, the higher the
              document's score.
            </li>
            <li>
              The rarer a term is across all documents in the index, the higher
              its contribution to the score.
            </li>
            <li>
              The more query terms that are found in a document, the higher its
              score.
            </li>
            <li>
              The more words that a field (e.g. title field, or text body field)
              contains, the lower its score. This factor penalizes documents
              with longer field values.
            </li>
            <li>
              Documents with more recent dates are given a slightly higher
              score.
            </li>
          </ul>

          <h3 id="filters">Filter search results</h3>
          <h4 id="searchcoverFT">Free full text access</h4>
          <p>
            You can filter your search to find free full text, either displayed
            within Europe PMC, or linked to a free and legal source.
          </p>
          <ul>
            <li>
              <b>Full text in Europe PMC:</b> All articles that are freely
              available to read within Europe PMC can be found with this filter.
              The full text of these articles is indexed, and can be searched
              using the Main search bar.
            </li>
            <li>
              <b>Link to free full text:</b> All articles with a known link to a
              free and legal copy of the full text, but that are not available
              to read within Europe PMC, can be found with this filter.
            </li>
          </ul>
          <p>
            To filter for open access license types, see
            <a href="/advancesearch">Advanced search</a>.
          </p>
          <h4 id="limitbycontent">Type</h4>
          <p>
            Filter your search to show only articles of the following content
            types, as shown in the example below:
          </p>
          <div>
            <img src="@/assets/typefilter.png" alt="The content type filter" />
          </div>
          <ul>
            <li>
              <b>Research articles:</b> articles presenting the results of
              experimental research.
            </li>
            <li>
              <b>Reviews:</b> reviews on a topic covering a broad set of
              previously published articles.
            </li>
            <li>
              <b>Preprints:</b> research articles that have not yet undergone
              formal peer-review and have been submitted to public preprint
              servers.
              <ul>
                <li>
                  <b>Journal published:</b> Filter for preprints that have been
                  published in a journal.
                </li>
                <li>
                  <b>Reviewed:</b> Filter for preprints that have been reviewed
                  or evaluated by peers.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            To find only preprints that have either been published in a journal
            or reviewed by peers, select both filters.
          </p>
          <h4 id="pubdatefilter">Publication date</h4>
          <p>
            Searches can be filtered by publication date, either by clicking on
            the year of publication, or entering a custom date range. This
            filter uses the date of first publication, or FIRST_PDATE using
            search syntax.
          </p>
          <h3 id="getFTA">Find the full text of an article</h3>
          <p>
            If the full text for an article is available on Europe PMC, the
            label 'Free to read' or "Free to read &amp; use" is shown in the
            search result. Clicking this label links straight to the full text
            of the article in Europe PMC. This is highlighted in the in the
            screenshot below:
          </p>
          <div>
            <img
              src="@/assets/fulltextlabel.png"
              alt="The full text labels"
              class="medium"
            />
          </div>
          <p>
            On the article page, if the free full text section is available on
            Europe PMC, a link is provided in the left menu.
          </p>
          <div>
            <img
              src="@/assets/articleleftmenu.png"
              alt="Article left menu with free full text link"
              class="smaller"
            />
          </div>
          <p>
            If a free, legal copy of the full text is found on an external
            website by Unpaywall, a link is shown with the Unpaywall logo.
          </p>
          <div>
            <img
              src="@/assets/unpaywalllogo.png"
              alt="Link with unpaywall logo"
              class="smaller"
            />
          </div>
          <p>
            If the full text is only available at the publisher or another
            external website, an external link icon is shown as below.
          </p>
          <div>
            <img
              src="@/assets/externalfulltext.png"
              alt="Link to publisher&amp;apos;s full text"
              class="smaller"
            />
          </div>

          <h3 id="citearticles">How to cite an article</h3>
          <h4 id="exportarticle">To export a single record</h4>
          <ol>
            <li>Go to the article page of the record.</li>
            <li>Follow the 'Get citation' link.</li>
            <li>
              From the popup window you can:
              <ul>
                <li>Add the citation to your export list.</li>
                <li>
                  Copy the citation text to your computer's clipboard, to paste
                  into another document.
                </li>
                <li>Email the citation to yourself or someone else.</li>
                <li>
                  Export the individual citation - select your desired format,
                  then 'Download'.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            An article citation typically includes the article title, author
            list, journal, publication date, DOI, PMID, PMCID. Article citations
            can be downloaded in
            <a href="/doc/Europe_PMC_Export_Formats_Feature_List.pdf"
              >several different formats</a
            >. Some citation export formats include additional metadata about
            the article. Full text is also available in XML format, but only for
            articles in the
            <a href="/downloads/openaccess">Europe PMC open access subset</a>.
          </p>
          <h4 id="exportmysearch">
            To export a selection of records from a search
          </h4>
          <ul>
            <li>
              Use the 'add to export list' link at the bottom of each record in
              the results which you wish to export. You can run multiple
              searches, adding different records from each to your export list.
            </li>
            <li>
              Follow the 'Export citations' link on the search results page.
              From here you can review your export list and remove individual
              citations if you wish.
            </li>
            <li>
              Select your desired file format. The 'BibTeX' and 'RIS' formats
              can be imported into many popular citation manager programs such
              as Mendeley, EndNote, RefWorks, CiteULike, Papers etc.
            </li>
            <li>Use the 'Download' button.</li>
          </ul>

          <h3 id="saveSearch">Save search & create alert</h3>
          <p>
            On the search results page, use the "Save & create alert" button
            located to the right of the main search box, to save your current
            search query and create an email alert. You'll then receive regular
            email updates of new results for the query, and you can unsubscribe,
            or edit the search query or email frequency anytime.
          </p>
          <div>
            <img
              src="@/assets/create-and-save-alert.png"
              alt="Create and save alert"
            />
          </div>
          <h4 id="sign-in-options">Sign in options</h4>
          <p>
            You will need to sign in to save an alert. You have the option to
            sign in with external services including ORCID, Twitter, or Europe
            PMC plus. Or you can sign in with, or create a Europe PMC account
            using your email address.
          </p>
          <h4 id="save-the-query">Save the query and create an alert</h4>
          <p>
            Clicking the Save & create alert button after sign-in opens a form
            that allows you to edit, name and save the query, and select
            frequency settings for an email alert.
          </p>
          <div>
            <img
              src="@/assets/alert-modal.png"
              alt="Alert modal"
              class="medium"
            />
          </div>
          <h4 id="edit-query">Edit query and test results</h4>
          <p>
            You can edit the search terms within the form. You will then be able
            to click "Test results" to see the number of results, or "View
            results in new window to test" to see all search results from the
            edited search terms within a new window.
          </p>
          <div>
            <img
              src="@/assets/edit-search-terms.png"
              alt="Edit search terms"
              class="medium"
            />
          </div>
          <h4 id="choose-whether-to-update">
            Choose whether and when to receive email updates
          </h4>
          <p>
            If you do not wish to receive email updates for new results, select
            "No".
          </p>
          <div>
            <img
              src="@/assets/receive-email-updates.png"
              alt="Receive email updates"
              class="small"
            />
          </div>
          <p>
            If you wish to receive email updates for new results, select a
            frequency, and in some cases a preferred day. Alerts are emailed at
            approximately 06:00 GMT on the day specified.
          </p>
          <div>
            <img
              src="@/assets/frequency-options.png"
              alt="Frequency options"
              class="medium"
            />
          </div>
          <div>
            <img
              src="@/assets/subfrequency-options.png"
              alt="Frequency options"
              class="smaller"
            />
          </div>
          <h4 id="add-or-edit-email">Add or edit your alerts email</h4>
          <p>
            If you've logged into Europe PMC using an external service such as
            ORCID, Twitter, or Europe PMC plus for the first time, you will be
            asked to add an email address for receiving alerts (Your email
            address is not shared with Europe PMC by external services.) An
            email will be sent to the email address entered, and you will need
            to verify it before receiving alerts.
          </p>
          <p>
            If an email address is already associated with your account, you
            have the option to add a different one, just for receiving alerts.
            After editing, an email will be sent to the account and will need to
            be verified before you will receive alerts at the new email address.
          </p>
          <div>
            <img
              src="@/assets/alert-email-input.png"
              alt="Alert email input"
              class="smallest"
            />
          </div>
          <h4 id="select-content">Select the content you'd like to receive</h4>
          <p>
            When you receive an email with updates, the title, author, and
            journal for any new results will be displayed. You can also choose
            to receive a partial abstract, which will display the first few
            lines of the abstract within the email.
          </p>
          <div>
            <img
              src="@/assets/alert-content.png"
              alt="Alert content"
              class="smaller"
            />
          </div>
          <h4 id="save-alert">Save the alert</h4>
          <p>
            Once you save the alert, you will be taken to the
            <a href="/accounts/SavedAlerts">Saved alerts</a> page, which can
            also be accessed from the account menu, in the top-right corner of
            the website. From this page you can view, edit, delete, or create
            new alerts. You can also change the email address for receiving
            alerts.
          </p>
          <div>
            <img src="@/assets/saved-alert-page.png" alt="Saved alerts page" />
          </div>
        </template>
        <template v-else-if="section.id === 'mostofsearch'">
          <h3 id="exactmatch">Exact match searches</h3>
          <p>
            Use quotation marks to retrieve publications containing your exact
            search phrase. For example,
            <em
              ><a href="/search?query=%22small%20cell%20lung%20cancer%22"
                >"Small cell lung cancer"</a
              ></em
            >
            retrieves fewer results than
            <em
              ><a href="/search?query=small%20cell%20lung%20cancer"
                >small cell lung cancer</a
              ></em
            >
            because the search in quotation marks looks for documents containing
            the exact phrase. The search without quotation marks finds documents
            containing all of the words small AND cell AND lung AND cancer, but
            the words may appear anywhere in the text of the document and are
            not necessarily in close proximity.
          </p>

          <h3 id="booleans">Boolean operators</h3>
          <p>
            Use the <a href="/advancesearch">Advanced search</a> form to
            facilitate searching, or manually enter search terms in the main
            search box. A Boolean operator is a logical connection; a word that
            is used to connect two or more search terms. Note that AND is the
            default operator where multiple search terms are used. You can use
            the AND, OR and NOT operators to control search results, for
            example:
          </p>
          <div class="table-overflow">
            <table class="dark">
              <thead>
                <tr>
                  <th>Search</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="/search?page=1&amp;query=vitamin+d+receptors+%28elderly+NOT+children%29&amp;sortby=Relevance"
                      >vitamin d receptors (elderly NOT children)</a
                    >
                  </td>
                  <td>
                    Vitamin d receptors where elderly is mentioned, and children
                    are not mentioned
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?page=1&amp;query=husbandry+%28cats+AND+dogs%29&amp;sortby=Relevance"
                      >husbandry (cats AND dogs)</a
                    >
                  </td>
                  <td>Husbandry where both cats and dogs are mentioned</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?page=1&amp;query=husbandry+%28cats+OR+dogs%29&amp;sortby=Relevance"
                      >husbandry (cats OR dogs)</a
                    >
                  </td>
                  <td>Husbandry where either cats or dogs are mentioned</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?page=1&amp;query=husbandry+%28cats+NOT+dogs%29&amp;sortby=Relevance"
                      >husbandry (cats NOT dogs)</a
                    >
                  </td>
                  <td>Husbandry where cats are mentioned, but not dogs</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?page=1&amp;query=TITLE:%22mouse%22+AND+AUTH:%22Chen+XJ%22+AND+PUB_YEAR:2004&amp;sortby=Relevance"
                      >TITLE:"mouse" AND AUTH:"Chen XJ" AND PUB_YEAR:2004</a
                    >
                  </td>
                  <td>
                    Articles where the word mouse is in the title and one of the
                    authors is 'XJ Chen', and the article was published in 2004.
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?page=1&amp;query=TITLE:%22mouse%22+NOT+PUB_YEAR:2004&amp;sortby=Date"
                      >TITLE:"mouse" NOT PUB_YEAR:2004</a
                    >
                  </td>
                  <td>
                    Articles where the word mouse is in the title and the
                    article was not published in 2004.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="wildcards">Wildcard operators</h3>
          <p>
            The wildcard asterisk character (*) can be used to facilitate
            searching. You can also use this operator to search for any word
            that starts with a base word, for example to search for any word
            that starts with "neuron":<br />
            <a href="/search?page=1&amp;query=neuron*&amp;sortby=Relevance"
              >neuron*</a
            >
          </p>
          <p>This will search for neuron, neurone, neuronal etc.</p>

          <h3 id="advancedform">Advanced search form</h3>
          <p>
            The <a href="/advancesearch">Advanced search</a> form provides a
            number of optional parameters, which help to create precise search
            queries without the need to remember search syntax.
          </p>
          <p>
            As a query is built, a 'query builder' box at the top of the form
            displays the search syntax used by Europe PMC. This syntax can be
            edited and copied. The query syntax can be pasted into the main
            Europe PMC search box. The journal name, author, funder and grant ID
            fields provide auto-suggest functionality in a drop-down menu to
            help you complete the fields. The journal name suggestions (as shown
            below) are ordered by the number of articles published.
          </p>
          <div>
            <img
              src="@/assets/advancedformjournals.png"
              alt="The journal dropdown on the advanced search page"
              class="medium"
            />
          </div>
          <p>
            If you know of a journal which publishes articles on a particular
            topic, you can search for content from journals directly from the
            main search box using acronyms or abbreviations. Europe PMC
            currently handles six commonly used acronyms and abbreviations:
          </p>
          <ul>
            <li>
              <a href="/search?query=emboj">EMBOJ</a> for
              <a href="/search/?query=journal:%22the+embo+journal%22"
                >The EMBO journal</a
              >.
            </li>
            <li>
              <a href="search?query=jama">JAMA</a> for
              <a
                href="/search?query=%28JOURNAL:%22Journal+of+the+American+Medical+Association%22%29"
                >Journal of the American Medical Association</a
              >.
            </li>
            <li>
              <a href="search?query=jbc">JBC</a> for
              <a
                href="/search?query=%28JOURNAL:%22The+Journal+of+biological+chemistry%22%29"
                >The Journal of Biological Chemistry</a
              >.
            </li>
            <li>
              <a href="search?query=nejm">NEJM</a> for
              <a
                href="/search?query=%28JOURNAL:%22The+New+England+journal+of+medicine%22%29"
                >The New England Journal of Medicine</a
              >.
            </li>
            <li>
              <a href="search?query=pnas">PNAS</a> for
              <a
                href="/search?query=%28JOURNAL:%22Proceedings+of+the+National+Academy+of+Sciences+of+the+United+States+of+America%22%29"
                >Proceedings of the National Academy of Sciences of the United
                States of America</a
              >.
            </li>
            <li>
              <a href="search?query=nar">NAR</a> for
              <a href="/search?query=%28JOURNAL:%22Nucleic+acids+research%22%29"
                >Nucleic Acids Research</a
              >.
            </li>
          </ul>
          <p>
            Europe PMC will consider adding other acronyms to the list if a
            request is sent to the <a href="/ContactUs">Helpdesk</a>.
          </p>
          <p>
            Many of the search fields support the selection of multiple values
            by using the grey '+' button beside the field. These can be combined
            together with AND, OR and NOT operators.
          </p>
          <div>
            <img
              src="@/assets/AdvancedSearchAddFields.png"
              alt="Add fields on advanced search page"
              class="medium"
            />
          </div>
          <h4 id="searchmetadata">Search within article metadata</h4>
          <p>
            If you wanted to find an article published in 2004, related to mouse
            research and knew one author was XJ Chen, you can use the Advanced
            Search to select the publication year 2004, and search using the
            'TITLE' field for "mouse".<br />
            <a
              href="/search?page=1&amp;query=TITLE:%22mouse%22+AND+%22Chen+XJ%22+AND+PUB_YEAR:2004"
              >TITLE:"mouse" AND "Chen XJ" AND PUB_YEAR:2004</a
            >
          </p>
          <h4 id="searchselsections">Search within an article section</h4>
          <p>
            You can search the following sections of articles: Abbreviations,
            Acknowledgements &amp; Funding, Appendix, Author Contribution, Case
            Study, Competing Interest, Conclusion, Discussion, Figures,
            Introduction and Background, Keywords, Materials and Methods,
            Others, References, Results, Supplementary Information and Tables.
          </p>
          <p>
            In the 'Filters' section of the
            <a href="/advancesearch">Advanced search</a> form, select the
            'Article Sections' drop down, where you can select one or more
            sections as required. The text-box to the right of the section
            allows for free-text input. In the example below the user has chosen
            to look for the term 'mRNA' in the 'Discussion' sections of
            articles, but only where the same term does not appear in the
            'Introduction and Background' sections of articles.
          </p>
          <div>
            <img
              src="@/assets/ArticleSections.png"
              alt="Article Sections dropdown in Filters section of advanced search"
              class="medium"
            />
          </div>
          <p>
            When building a search as in the example above, the following search
            query is generated:<br />
            <a
              href="/search/?scope=fulltext&amp;page=1&amp;query=%28DISCUSS%3A%22mRNA%22+NOT+INTRO%3A%22mRNA%22%29"
              >(DISCUSS:"mRNA" NOT INTRO:"mRNA")</a
            >
          </p>
          <p>
            To enable section searching Europe PMC full text content has been
            indexed at the section-level, where the sections can be identified.
            The ability to identify the sections depends on the structure of the
            articles provided, and this can vary. Depending on the section, the
            coverage from the open access subset of Europe PMC, for example,
            varies from 1% to 80%.
          </p>
          <h4 id="searchusingmesh">MeSH terms and synonyms</h4>
          <p>
            MeSH (Medical Subject Heading) terms are assigned to most PubMed
            records, not Europe PMC full text.
          </p>
          <p>
            An option is provided on the
            <a href="/advancesearch">Advanced search</a> form that will
            include/exclude synonyms based on the MeSH vocabulary from a search
            as shown below:
          </p>
          <div>
            <img
              src="@/assets/Filters.png"
              alt='Filters section, option "Synonym query expansion"'
            />
          </div>
          <p>
            For example, a search for 'cat' will also include 'felis domestica'
            if synonyms are turned on. Synonym query expansion is off by
            default.
          </p>
          <h3 id="searchsyntax">Search syntax</h3>
          <p>
            Search syntax can help to narrow your search to specific fields or
            metadata. For example, to restrict your search to article titles
            only, you could enter:<br />
            TITLE:"Leukotriene E4 Th2".
          </p>
          <p>
            View the <a href="/searchsyntax">Search syntax reference</a> for a
            guide to the search syntax available.
          </p>
          <p>
            The <a href="/advancesearch">Advanced search page</a> provides many
            options to make your search more specific, without the need to
            recall search syntax.
          </p>
        </template>
        <template v-else-if="section.id === 'epmccontent'">
          <h3 id="contenttypes">Types of content</h3>
          <div id="count-graph">
            <div class="column">
              <div class="circle medium patents">
                <div>
                  <b>Patents</b><br />
                  <a
                    class="count"
                    :href="'/search?query=' + counts.patent.query"
                    >{{ counts.patent.count }}</a
                  >
                </div>
              </div>
              <div class="circle medium full-text">
                <div>
                  <b>Full text articles</b><br />
                  <a
                    class="count"
                    :href="'/search?query=' + counts.fulltext.query"
                    >{{ counts.fulltext.count }}</a
                  >
                </div>
              </div>
            </div>
            <div class="column middle">
              <div class="circle large abstracts">
                <div>
                  <b>Abstracts</b><br />
                  <a
                    class="count"
                    :href="'/search?query=' + counts.abstract.query"
                    >{{ counts.abstract.count }}</a
                  >,
                  <a
                    class="count"
                    :href="'/search?query=' + counts.pubmed.query"
                    >{{ counts.pubmed.count }}</a
                  >
                  from PubMed
                </div>
              </div>
            </div>
            <div class="column last">
              <div class="circle small nhs">
                <div>
                  <b>NHS guidelines</b><br />
                  <a
                    class="count"
                    :href="'/search?query=' + counts.nhs.query"
                    >{{ counts.nhs.count }}</a
                  >
                </div>
              </div>
              <div class="circle small preprints">
                <div>
                  <b>Preprints</b><br />
                  <a
                    class="count"
                    :href="'/search?query=' + counts.preprint.query"
                    >{{ counts.preprint.count }}</a
                  >
                </div>
              </div>
              <div class="circle small agricola">
                <div>
                  <b>Agricola records</b><br />
                  <a
                    class="count"
                    :href="'/search?query=' + counts.agricola.query"
                    >{{ counts.agricola.count }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <p>
            The content scope of Europe PMC covers both abstracts and full text
            articles. Some full text articles are available as open access
            content that can be downloaded and reused from the
            <a href="/downloads/openaccess">Europe PMC open access subset</a>.
            Find out more about the
            <a href="#howcontentadded"
              >sources of content and how content is added to Europe PMC</a
            >.
          </p>

          <h3 id="absFTAdiff">
            Differences between abstracts and full text articles
          </h3>
          <p>
            An abstract is a brief summary of a research article. You can view
            the abstract of an article in Europe PMC by following on a search
            result.
          </p>
          <p>
            If available, the full text of an article is available from the
            'free to read' label in the search results, or by following the full
            text link in the left menu of the article page.
          </p>
          <p>
            If the article is under a publisher's embargo, the date when the
            full text is due to be released by the publisher (the embargo date)
            is shown on the article page.
          </p>
          <p>
            Not all articles in Europe PMC include the full text. Journals that
            deposit their articles in PMC, whose content is mirrored in Europe
            PMC, fall into one of three categories:
          </p>
          <ul>
            <li>
              For several hundred journals, Europe PMC has the complete contents
              of each issue, starting with the first issue. For the older
              journals in this group, the back issues of a journal (generally
              anything prior to the late 1990s) are available as digitized
              (scanned) copies of the original print journal.
            </li>
            <li>
              For a smaller group of journals, Europe PMC has complete issues
              and volumes for recent years, but not for all the early years of
              the journal.
            </li>
            <li>
              For other journals, Europe PMC does not contain any complete
              issues, just a selection of articles, e.g. those that the journal
              has published as open access articles.
            </li>
          </ul>
          <p>
            In all three categories, Europe PMC has a journal's final published
            version of the respective articles. In addition to the final
            versions of articles provided by publishers, Europe PMC contains
            <a href="#authmanuscripts">author manuscripts</a> of selected
            articles from several thousand other journals.
          </p>

          <h3 id="preprints-section">Preprints</h3>
          <p>
            Since 2018, Europe PMC has indexed
            {{ counts.preprint.count }} preprints, and versions when available,
            to make them searchable alongside journal published articles.
            Similar to other articles in Europe PMC, preprints are linked to
            data behind the paper, can be claimed to an ORCID, included in
            citation networks, and linked to platforms that comment on or peer
            review preprints.
          </p>
          <p>
            A preprint is a complete scientific manuscript that an author
            uploads on a public server for free viewing. Initially it is posted
            without peer review, but may acquire feedback or reviews as a
            preprint, and may eventually be published in a certified
            peer-reviewed journal.
          </p>
          <p>
            For more information about the inclusion of preprints in Europe PMC,
            and the COVID-19 preprints initiative, see
            <a href="/Preprints">Preprints in Europe PMC</a>.
          </p>

          <h3 id="sources">Differences between PubMed, PMC and Europe PMC</h3>
          <p>
            Overall, Europe PMC contains more records than both PubMed and
            PubMed Central (PMC). In brief, Europe PMC contains all of the
            PubMed abstracts, the vast majority of PMC content, plus additional
            content including preprints, microPublications, patents, NHS
            clinical guidelines and Agricola records.
          </p>
          <p>
            Full text publisher-provided content in PMC is subject to PMC
            Participation Agreements. Since July 2006, when Europe PMC (then
            branded as UKPMC) became the first PMC International centre, all PMC
            Participation Agreements have included permission to make a
            participating journal's content available at Europe PMC. Of those
            whose Participation Agreement pre-dates this, most agreed to this
            wider distribution of content, however some did not which means that
            approximately 250,000 articles in PMC are not available from Europe
            PMC; the vast majority of these are back issue articles.
          </p>

          <h3 id="authmanuscripts">Author manuscripts</h3>
          <p>
            <a href="/Funders/">Europe PMC Funders' Group</a> organisations
            mandate that published research, arising from the research grants
            they award, must be made available through Europe PMC. One way this
            can be achieved is for authors to submit to Europe PMC (via
            <a href="//plus.europepmc.org/">Europe PMC plus</a>) the final, peer
            reviewed manuscripts of such articles once they have been accepted
            for publication (also known as the author's accepted manuscript).
            Papers submitted to Europe PMC are made accessible through both
            Europe PMC and PMC.
          </p>
          <p>
            Some funders, most notably Wellcome, prefer the final, published
            version of the article to be archived in Europe PMC and have made
            <a
              href="//wellcome.ac.uk/funding/managing-grant/how-get-open-access-funding"
              >additional funding<i class="fas fa-external-link-alt"></i
            ></a>
            available to cover the costs related to open access (OA) article
            processing charges. In such cases, the publisher - as part of the OA
            fee - will take responsibility for uploading the final, published
            version of the article into PMC/Europe PMC. However, where
            publishers do not offer an OA option, then authors are required to
            self-archive author manuscripts in Europe PMC.
          </p>
          <h4 id="authmandiff">
            Differences between author manuscripts and journal articles
          </h4>
          <p>
            Most articles on Europe PMC are provided by the publisher and appear
            as they would on the publisher site. A small proportion of articles
            enter Europe PMC as author manuscripts; in this case the article
            content is what has been accepted by the journal for publication but
            it appears without the journal's house style.
          </p>
          <p>
            When an author submits an article to a journal, it is reviewed by
            one or more independent peer reviewers and the journal's editors,
            who decide whether to accept it for publication. As part of this
            process, the author may be asked to revise the article to meet the
            journal's standards for acceptance. The final accepted manuscript
            submitted to Europe PMC (the author manuscript) is the version that
            the journal accepted for publication, including any revisions that
            the author made during the peer review process.
          </p>
          <p>
            The published version of the article usually includes additional
            changes made by the journal's editorial staff after acceptance of
            the author's final manuscript. These edits may be limited to matters
            of style and format or they could include more substantive changes
            made with the concurrence of the author.
          </p>
          <p>
            When Europe PMC displays the author manuscript version of an
            article, the source data preceding the article title includes a
            reference to the published article, as shown below.
          </p>
          <div>
            <img
              src="@/assets/AuthorSubmittedManuscript.png"
              alt='Author manuscript showing text saying "Published in final edited form as publisher version"'
            />
          </div>

          <h3 id="journalsinEPMC">Journals</h3>
          <p>
            The Europe PMC <a href="/journalList">Journal List</a> includes
            journals which deposit content in Europe PMC. Content from a wide
            range of other journals is also included in Europe PMC from other
            sources. Use the journal field in the Advanced Search to search for
            specific journal names.
          </p>

          <h3 id="otherlanguages">Non-English articles</h3>
          <p>
            Almost all the material in Europe PMC is in English. A few journals
            in Europe PMC publish material in more than one language. For
            instance, the
            <a
              href="/search?page=1&amp;query=ISSN:%220820-3946%22&amp;restrict=All+results"
              >Canadian Medical Association Journal</a
            >
            has some articles in (both) French and English. In this case, only
            the English material appears in Europe PMC's primary presentation of
            an article – the html full-text display. However, the journal's PDF
            version of the article may contain material in French as well as
            English. Another example is the
            <a href="/articles/PMC3419004?pdf=render"
              >Canadian Family Physician</a
            >
            journal, which contains some articles that are available only in
            French; in this case Europe PMC provides this in both the full-text
            display and the journal's PDF version.
          </p>
          <p>
            On the <a href="/advancesearch">Advanced search</a> page there is an
            option to filter your search by language. In this instance, the
            languages listed refer to the original language of the article.
            Abstracts are converted into English once submitted to PubMed.
          </p>

          <h3 id="restrictions">
            Copyright restrictions on the use of material
          </h3>
          <p>
            Although access to the material in Europe PMC is free, the use of
            the material is still subject to the copyright and/or related
            license terms of the respective authors or publishers. See the
            <a href="/Copyright">Europe PMC Copyright Notice</a> for more
            information.
          </p>
          <p>
            You may not use any kind of automated process to download articles
            in bulk from the main Europe PMC site. Europe PMC will block the
            access of any user who is found to be violating this policy. Batch
            downloading of content in Europe PMC is supported by various methods
            including an <a href="/FtpSite">FTP site</a>, the
            <a href="/OaiService">Europe PMC-OAI</a> service, and
            <a href="/RestfulWebService">RESTful</a> and
            <a href="/SoapWebServices">SOAP</a> web services – the content
            available via each method is summarised on the
            <a href="/developers">Developers page</a>.
          </p>
          <p>
            See the <a href="/FtpSite">Europe PMC Open Access Subset</a> page
            for more information.
          </p>

          <h3 id="howORCIDsused">ORCIDs</h3>
          <p>
            Europe PMC has integrated ORCIDs iDs—unique identifiers for authors,
            available from
            <a href="//orcid.org/"
              >ORCID<i class="fas fa-external-link-alt"></i></a
            >—into its website, search systems, and web services via the
            ORCID-based <a href="/orcid/import">Article claiming tool</a> (also
            see <a href="#linktoORCID">Link articles to your ORCID</a>). This is
            useful for authors who want to display their publications list
            unambiguously on the Europe PMC website, allowing them to show for
            each article citation counts, linked data sets, and full text
            availability in Europe PMC. Millions of Europe PMC articles have
            been linked to hundreds of thousands of ORCID iDs, a count that
            grows significantly with each update from the ORCID foundation.
          </p>
          <p>ORCID iDs are now shown in two ways on articles:</p>
          <ol>
            <li>In the article author list under the article title.</li>
            <li>
              As a list of ORCID iDs in the author information section of an
              article.
            </li>
          </ol>
          <p>
            See, for example,
            <a href="/article/MED/11237011"
              >the article that describes the initial sequence of the human
              genome</a
            >.
          </p>
          <p>
            If you search for an author using last name and first name or
            initial, up to two suggested authors are shown above your search
            results if authors with matching names and an ORCID iD are found.
          </p>
          <div>
            <img
              src="@/assets/SuggestedAuthors.png"
              alt="Example suggested authors panel"
            />
          </div>
          <p>
            ORCID iDs are also used to create the author profile pages in Europe
            PMC. For example,
            <a href="/authors/0000-0002-6982-4660"
              >Alex Bateman's author profile</a
            >.
          </p>
          <h4 id="orcidsforreaders">Benefits for readers</h4>
          <p>
            ORCID-based author searching is particularly useful to find people
            who have common last names, or have changed their name, or
            contributed as part of a consortium.
          </p>
          <h4 id="orcidsforauthors">Benefits for authors</h4>
          <p>
            Articles which have been claimed to ORCID (e.g. using Europe PMC's
            article claiming tool) and are publicly available will have an ORCID
            iD icon by the author's name. Opening the tooltip by hovering or
            tapping on the author name shows the author's affiliation details
            which helps to uniquely identify the author. The article will also
            appear in the <a href="#authorprofile">author's profile page</a>.
          </p>

          <h3 id="impact">Citations &amp; Impact</h3>
          <p>
            From the article page, you can use the Citations &amp; Impact tab to
            see a summary of:
          </p>
          <ul>
            <li>Articles citing this article</li>
            <li>Database records citing this article</li>
            <li>Recommendations of this article</li>
          </ul>
          <p>
            You will see up to 5 articles which cite the original article, with
            a link to view all citing articles.
          </p>
          <h4 id="citationsnetwork">About the Europe PMC citations network</h4>
          <p>
            To show citation counts for individual articles in Europe PMC
            requires that reference lists for as many relevant articles as
            possible are known, and that the citations within those reference
            lists can be identified uniquely, for example, by resolving to a
            PubMed ID. The number of "Cited By" articles listed for any
            individual article depends not only on the scientific importance of
            the article, but also on the size and scope of the dataset from
            which the citations have been extracted.
          </p>
          <h4 id="whyEpmcHasFewer">
            Why are Europe PMC citation counts different to Google Scholar, Web
            of Science or Scopus?
          </h4>
          <p>
            The citation dataset available to Europe PMC is based on open
            citation data and is smaller than those held by subscription-based
            services such as Web of Science or Scopus. Google Scholar also
            include citations in content other than peer reviewed articles.
            Therefore, the number of citing articles that Europe PMC shows for
            any given article is likely to be comparatively smaller than the
            number you would see using these services.
          </p>
          <h4 id="citationsincomplete">
            Why is the citation information for some articles incomplete?
          </h4>
          <p>
            Occasionally references in the source article are missing because
            there is insufficient information or formatting for them to be
            displayed on Europe PMC.
          </p>

          <h3 id="contentsources">Sources of content in Europe PMC</h3>
          <p>Links to content providers are given in the following list:</p>
          <div class="table-overflow">
            <table class="dark">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Definition</th>
                  <th>Details of data source</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AGR</td>
                  <td>Agricola</td>
                  <td>
                    Agricola is a bibliographic database of citations to the
                    agricultural literature created by the US National
                    Agricultural Library and its co-operators.
                    <a href="//agricola.nal.usda.gov"
                      >http://agricola.nal.usda.gov<i
                        class="fas fa-external-link-alt"
                      ></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>CBA</td>
                  <td>Chinese Biological Abstracts</td>
                  <td>
                    CBA and the Shanghai Institutes for Biological Sciences
                    (SIBS) provide EBI with citation data not available in
                    MEDLINE.
                  </td>
                </tr>
                <tr>
                  <td>CTX</td>
                  <td>CiteXplore</td>
                  <td>
                    Manual user-submitted records, added by EMBL-EBI, or new
                    content types such as microPublications.
                  </td>
                </tr>
                <tr>
                  <td>ETH</td>
                  <td>EthOs Theses</td>
                  <td>PhD theses (British Library)</td>
                </tr>
                <tr>
                  <td>HIR</td>
                  <td>NHS Evidence</td>
                  <td>UK Clinical guidelines</td>
                </tr>
                <tr>
                  <td>MED</td>
                  <td>PubMed/MEDLINE NLM</td>
                  <td>
                    The National Library of Medicine (NLM) (<a
                      href="//www.nlm.nih.gov/"
                      >http://www.nlm.nih.gov/<i
                        class="fas fa-external-link-alt"
                      ></i></a
                    >) is the world's largest biomedical library. It explores
                    the uses of computer and communication technologies to
                    improve the organization and use of biomedical
                    information.<br /><br />MEDLINE: (<a
                      href="//www.nlm.nih.gov/medline/medline_home.html"
                      >https://www.nlm.nih.gov/medline/medline_home.html<i
                        class="fas fa-external-link-alt"
                      ></i></a
                    >) the National Library of Medicine's database of
                    bibliographic citations and abstracts in the fields of
                    medicine, nursing, dentistry, veterinary medicine, health
                    care systems, and preclinical sciences.<br /><br />PubMed:
                    (<a href="//www.ncbi.nlm.nih.gov/pubmed"
                      >http://www.ncbi.nlm.nih.gov/pubmed<i
                        class="fas fa-external-link-alt"
                      ></i></a
                    >) the NLM's database of citations for biomedical literature
                    from MEDLINE, life science journals, and online books
                  </td>
                </tr>
                <tr>
                  <td>NBK</td>
                  <td>Europe PMC Book metadata</td>
                  <td>
                    This source type denotes full text books on the Europe PMC
                    Bookshelf that are not initially provided with a PMID 'MED'
                    source metadata record.<br /><br />Where a full text book is
                    received from the NCBI without a corresponding PMID 'MED'
                    type metadata record, an 'NBK' metadata record will be
                    created with the same 'NBK' number as the book. The metadata
                    will be replaced by a PMID 'MED' type record if later made
                    available. The full text book always retains its 'NBK'
                    number.
                  </td>
                </tr>
                <tr>
                  <td>PAT</td>
                  <td>Biological Patents</td>
                  <td>
                    <a href="//www.epo.org/"
                      >http://www.epo.org/<i
                        class="fas fa-external-link-alt"
                      ></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>PMC</td>
                  <td>PubMed Central</td>
                  <td>
                    PMC is a free full-text archive of biomedical and life
                    sciences journal literature at the U.S. National Institutes
                    of Health's National Library of Medicine (NIH/NLM).
                    <a href="//www.ncbi.nlm.nih.gov/pmc/"
                      >http://www.ncbi.nlm.nih.gov/pmc/<i
                        class="fas fa-external-link-alt"
                      ></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td>PPR</td>
                  <td>Preprints</td>
                  <td>
                    Preprints are articles which have not been peer-reviewed
                    from various preprint servers and open research platforms
                    such as bioRxiv, ChemRxiv, PeerJ Preprints and F1000.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="howcontentadded">How content is added to Europe PMC</h3>
          <h4 id="howjournals">Journals and books</h4>
          <p>
            Europe PMC mirrors the journal and book content in PubMed and PMC,
            with the exception of a few disabled journals, whose publishers did
            not give permission before the 2006 PMC Participation Agreements
            were in place. The process of adding journals is managed by the
            NCBI. A
            <a href="//www.ncbi.nlm.nih.gov/pmc/pub/pubinfo/"
              >guide<i class="fas fa-external-link-alt"></i
            ></a>
            and
            <a href="//pmc.ncbi.nlm.nih.gov/about/faq/#faq-pub"
              >FAQ<i class="fas fa-external-link-alt"></i
            ></a>
            is provided to explain the process of adding journals for
            publishers, and also to take them through an application. There is
            also
            <a href="//www.ncbi.nlm.nih.gov/books/about/publishers/"
              >guidance for publishers<i class="fas fa-external-link-alt"></i
            ></a>
            that would like to add books to the Europe PMC Bookshelf.
          </p>
          <h4 id="howpreprints">Preprints</h4>
          <p>
            The metadata and abstracts of preprints are added to Europe PMC from
            the Crossref metadata service typically within 24 hours of the
            preprint being published.
          </p>
          <p>
            Europe PMC indexes preprints from the following preprint servers:
          </p>
          <ul>
            <li v-for="preprint in preprints" :key="preprint.name">
              {{ preprint.name }}
            </li>
          </ul>
          <h4 id="howmanuscripts">Author manuscripts</h4>
          <p>
            Authors funded by Europe PMC funders submit manuscripts to Europe
            PMC if the published version is not available.
          </p>
          <h4 id="howgrants">Grant data</h4>
          <p>
            Grant award data is currently sent to the Europe PMC Helpdesk where
            it is first loaded into GRIST (GRant Information SysTem), and then
            into Europe PMC plus. Grant data is publicly available (with the
            exception of email addresses) via the
            <a href="/grantfinder">grant finder</a> and the
            <a href="/GristAPI">GRIST API</a>. Grant-article associations are
            processed into Europe PMC in the following ways:
          </p>
          <h4 id="howpatents">Patents</h4>
          <p>
            Patents in Europe PMC were provided by the European Patent Office.
          </p>
          <h4 id="howorcids">ORCIDs</h4>
          <p>ORCID-article associations are provided in one of two ways:</p>
          <ul>
            <li>Authors link articles in Europe PMC to their ORCID record.</li>
            <li>
              Europe PMC receives ORCID-article associations from ORCID.org.
              Articles may have been linked to an ORCID by an author via another
              source, or automatically by a publisher.
            </li>
          </ul>
          <h4 id="howannotations">Annotations</h4>
          <p>
            Annotations on abstracts and full text articles in Europe PMC are
            provided by EMBl-EBI and other text mining groups. See the
            <a href="/AnnotationsApi">Annotations API page</a> for a list of
            annotation types and providers.
          </p>
          <h4 id="howcitations">Citations</h4>
          <p>
            Citations in Europe PMC are based on open citation data from PubMed
            Central and CrossRef. See more
            <a href="#citationsnetwork">about the Europe PMC citation network</a
            >.
          </p>
        </template>
        <template v-else-if="section.id === 'linkeddata'">
          <p>
            Europe PMC links articles to supporting data and resources,
            including supporting and related data, reviews, protocols and lay
            summaries. Find out more about linked data and resources below.
          </p>
          <h3 id="figures">Figures</h3>
          <p>
            On some articles with full text in Europe PMC, a figure preview is
            available underneath the abstract, which allows you to jump to see
            the figure in the full text in context. Figures can only be shown
            under the abstract if the article has a license that allows reuse
            and modification of the article (read more about Open Access).
          </p>
          <div>
            <img
              src="@/assets/figurecarousel.png"
              alt="Figure carousel here with the selected figure underneath."
              class="medium"
            />
          </div>
          <p>
            For all full text articles the figures they will appear in the full
            text section of the article, in context.
          </p>

          <h3 id="datalinks">Data</h3>
          <p>
            If supporting data behind the paper is available, including
            supplementary materials and text-mined citations of data accessions,
            a link to the biological study in BioStudies can be found in the
            Data section.
          </p>
          <p>
            Europe PMC text mines full text articles and abstracts for
            biological entities and concepts. Several types of terms are
            text-mined, including gene symbols, diseases, chemicals, organisms,
            Gene Ontology terms, and accession numbers. Text-mined terms can be
            browsed in the Data section. Protein Data Bank (PDBe), and some
            chemical structures can be viewed in this section. Where this is the
            case a 'View Structure' link will appear along with the data link.
          </p>
          <div>
            <img
              src="@/assets/ViewStructure.png"
              alt="Highlighted &amp;apo;View structure&amp;apos; link and display"
              class="medium"
            />
          </div>
          <p>
            Accession numbers represent specific data citations, similar to
            article citations within an article.
          </p>
          <p>
            When database records are submitted or curated, references to the
            literature are frequently added, and made available in the Data
            section. You can link from the article to the original database
            record which cites the article. For example in the screenshot below
            there are 65 different UniProt records that cite one article. Other
            databases that provide cross-links in this way include ENA,
            InterPro, PDB, IntAct, ChEMBL, ChEBI and ArrayExpress.
          </p>
          <div>
            <img
              src="@/assets/Bioentities.png"
              alt="Example with many data links"
              class="medium"
            />
          </div>

          <h3 id="reviews">Reviews & evaluations</h3>
          <h4 id="notifications-of-reviews-and-evaluations">
            Notification of Reviews & evaluations
          </h4>
          <p>
            If there are reviews or evaluations available for a preprint, the
            notification near the top of the page will provide a link to the
            Reviews & evaluations section.
          </p>
          <div>
            <img
              src="@/assets/reviews-and-evaluation-notification-banner.png"
              alt="Reviews and evaluation notification banner"
              class="medium"
            />
          </div>
          <p>
            If there are no reviews or evaluations available for the current
            preprint version, but they are available for another version, then
            the notification will suggest looking at Version history to link to
            reviews on another version.
          </p>
          <div>
            <img
              src="@/assets/reviews-and-evaluation-notification-banner-with-version-history.png"
              alt="Reviews and evaluation notification banner with version history"
              class="medium"
            />
          </div>
          <h4 id="reviews-and-evaluation-section">
            The Reviews & evaluations section
          </h4>
          <p>
            You can find links to reviews, recommendations, and commentary from
            expert sources in the Reviews & evaluations section of the article
            page. These are sourced from a variety of different community
            groups, peer review platforms and publishers. Peer review
            evaluations are aggregated by Sciety, visit
            <a href="https://sciety.org/groups">Groups on Sciety</a> for more
            information.
          </p>
          <p>
            The review or evaluation will include the name of the review source
            and a link to the review, and if available it will also include a
            logo for the source, the author or referee of the review, and the
            date of the review. If dates are available, a timeline will display
            with the most recent event at the bottom.
          </p>
          <div>
            <img
              src="@/assets/review-timeline.png"
              alt="Review timeline"
              class="medium"
            />
          </div>

          <h3 id="protocols">Protocols &amp; materials</h3>
          <p>
            To aid reproducibility of research, Europe PMC includes links from
            articles to protocols and materials such as cell line which were
            used for the study, in the Protocols and Materials section of the
            article page.
          </p>

          <h3 id="summaries">Lay summaries</h3>
          <p>
            A lay summary aims to summarise scientific research in an accessible
            way, interpreting complex scientific ideas and findings for people
            without scientific training, for example journalists, policy makers,
            patients and professionals working in the health and fitness
            industry. The Lay summary section includes press releases and
            summaries from external websites.
          </p>

          <h3 id="similararts">Similar articles</h3>
          <p>
            To arrive at the top five similar articles an algorithm is used to
            compare words from the Title and Abstract of each article.
          </p>

          <h3 id="funding">Funding</h3>
          <p>
            The funding section contains details of grants and funders that
            supported the research. If the research was funded by a Europe PMC
            funder, a link to the grant details page will be provided, where you
            can find a description of the grant, Principal Investigator names
            and affiliations and the amount and dates of the grant award.
          </p>

          <h3 id="keyterms">Key terms</h3>
          <p>
            Key terms, are text-mined, biological entities and concepts, or
            annotations on the article text, which can be highlighted, making it
            easier to scan an article quickly and extract useful information and
            evidence. In addition to entities (gene/protein names, organisms,
            diseases, chemicals, Gene Ontology terms), Key terms highlights
            biological events (e.g. phosphorylation), functional relations (e.g.
            gene–disease associations, protein–protein interactions), as well as
            biological functions (e.g. gene function). For more information on
            using key terms, or contributing annotations to Europe PMC, visit
            the <a href="/annotations">SciLite annotations page</a>.
          </p>
          <h4 id="reportterms">Report incorrect annotations</h4>
          <p>
            If you spot an incorrect annotation, or the annotation is too
            generic and is highlighted too often, you can report it by clicking
            or tapping on the highlighted term and using the 'Feedback' link in
            the popup window. You can also endorse annotations using the
            Feedback link, if they are useful to you.
          </p>
          <h4 id="whyterms">
            Why do Key terms not appear on every full-text article in Europe
            PMC?
          </h4>
          <p>
            Europe PMC is only able to highlight Key terms on articles with a
            CC-BY, CC-BY-NC, CC0, CC-BY-SA OR CC-BY-NC-SA license. Currently
            this represents about
            <a
              class="count"
              :href="'/search?query=' + counts.openaccess.query"
              >{{ counts.openaccess.count }}</a
            >
            articles.
          </p>
          <p>
            With the Advanced Search form you can more precisely find any of the
            data or resource links by specifying the provider of interest in the
            External Links drop-down at the bottom of the form as shown below:
          </p>
          <div>
            <img
              src="@/assets/AdvancedSearchExternalLinks.png"
              alt="External links dropdown on advanced search page"
              class="medium"
            />
          </div>
          <h4 id="participate">
            Participate in the Europe PMC external links service
          </h4>
          <p>
            The external links service is a mechanism for external providers to
            publish links from articles in Europe PMC to related data,
            information or tools. More information on the service can be found
            using the link on the top navigation bar (see Tools &gt; External
            links service), or directly at
            <a href="/LabsLink">External links service</a>.
          </p>
          <p>
            Please email us at
            <a href="mailto:labslink@europepmc.org">labslink@europepmc.org</a>,
            briefly describing the links you wish to share. We will assign you
            with the information you need to generate the files and upload your
            links.
          </p>
        </template>
        <template v-if="section.id === 'authorservices'">
          <h3 id="linktoORCID">Link articles to your ORCID</h3>
          <p>
            Europe PMC offers researchers the ability to
            <a href="/orcid/import"
              >link articles to their ORCID (a unique researcher ID).</a
            >
          </p>
          <h4>To claim an article you are viewing</h4>
          <ol>
            <li>
              <strong>Find the article.</strong> Find the article on EUROPE PMC
              that you would like to claim to your ORCID profile.
            </li>
            <li>
              <strong>Give ORCID permission to claim the article.</strong> Find
              the "Claim to ORCID" link in the right hand column, follow it to
              give ORCID permission to claim the article. You will then be
              directed to sign into ORCID, and afterwards a popup will confirm
              that your article has been claimed.
            </li>
          </ol>
          <h4>
            To view and claim multiple articles that might be authored by you
          </h4>
          <ol>
            <li>
              <strong>Find the ORCID article claiming tool.</strong> Select
              Tools &gt; ORCID article claiming on Europe PMC.
            </li>
            <li>
              <strong>Select your publications.</strong> After you have signed
              in via ORCID, Europe PMC presents you with a list of articles
              using the names and initials in your ORCID profile (from articles
              within Europe PMC). Select the publications that you wish to link
              using the check-box next to the article title.
            </li>
            <li>
              <strong>Review your bibliography and send to ORCID.</strong> Check
              the articles that you have selected and update your ORCID profile
              with the articles by using the 'send to ORCID' button.
            </li>
          </ol>
          <p>
            The associations made using the article claiming tool become
            available in Europe PMC within a few minutes. Find out more about
            <a href="#howORCIDsused"
              >how ORCIDs are displayed and used on Europe PMC</a
            >.
          </p>

          <h3 id="authorprofile">Author profiles</h3>
          <p>
            If you have an
            <a href="//orcid.org/"
              >ORCID iD<i class="fas fa-external-link-alt"></i></a
            >, then by default you will have a Europe PMC profile page. An ORCID
            iD is a unique identifier which distinguishes you from other
            researchers. All data displayed on Europe PMC author profiles is
            openly available from Europe PMC and ORCID.org. Any publications
            which you have
            <a href="/orcid/import">linked to your ORCID iD</a> (and are marked
            as visible to Public, Everyone, or Trusted Parties) will be
            displayed on your Europe PMC profile page.
          </p>
          <p>
            You can download an image of the chart on your profile (in PNG,
            JPEG, PDF or SVG format) to include in your CV or website by using
            the Download chart button.
          </p>
          <div>
            <img
              src="@/assets/downloadchart.png"
              alt="Image of the &amp;apos;Download chart&amp;apos; button"
              class="medium"
            />
          </div>
          <h4 id="whynopublications">
            Why are there no publications displayed on my author profile?
          </h4>
          <p>
            There are two possible reasons why publications are not displaying
            on your Europe PMC author profile:
          </p>
          <ol>
            <li>
              You have not linked any of your publications in Europe PMC to your
              ORCID iD. Use our simple tool to
              <a href="/orcid/import">link your articles to your ORCID iD</a>.
            </li>
            <li>
              Your 'works' in your ORCID profile are marked as only being
              visible to you. You will need to
              <a
                href="//support.orcid.org/hc/en-us/articles/360006897614-Visibility-settings"
                >update your ORCID visibility settings<i
                  class="fas fa-external-link-alt"
                ></i></a
              >.
            </li>
          </ol>
          <p>
            If you have any problems linking publications to your ORCID iD,
            please contact the <a href="/ContactUs">Helpdesk</a>.
          </p>
          <h4 id="preventpublications">
            Prevent publications from being displayed on your author profile
          </h4>
          <p>
            <a
              href="//support.orcid.org/hc/en-us/articles/360006897614-Visibility-settings"
              >Modify your visibility settings<i
                class="fas fa-external-link-alt"
              ></i
            ></a>
            on the ORCID website. Any publications marked as Private or Only Me
            will not be displayed on your Europe PMC profile.
          </p>
          <h4 id="accessprofile">Accessing another author's profile</h4>
          <p>
            All author profiles on Europe PMC are public. There are various ways
            to access an author's profile, including:
          </p>
          <ul>
            <li>
              Search for the author by name in the main search bar. Up to two
              suggested authors, who have linked their ORCID to Europe PMC, are
              shown at the top of the search results. The authors with the most
              publications are shown first.
            </li>
            <li>
              Link to an author's profile from an article page, under the
              section 'ORCIDs linked to this article'.
            </li>
            <li>
              Search for the author by name in the Advanced Search - if they
              have an ORCID iD which has been linked to Europe PMC it will be
              displayed in the suggestions.
            </li>
            <li>Enter the author's ORCID iD in the main search box.</li>
          </ul>
          <h4 id="opencitations">Open citations on author profiles</h4>
          <p>
            On Europe PMC author profile pages, open citations are based on the
            reference sections of articles which are available through open data
            sets. This includes full text articles in Europe PMC and citation
            services such as CrossRef. Find out more about
            <a href="#citationsnetwork">Europe PMC's citation network</a>.
          </p>

          <h3 id="submitmanuscript">Submit a manuscript</h3>
          <p>
            Authors funded by at least one of the Europe PMC Funders can submit
            an author manuscript for inclusion in Europe PMC. Each submission
            must be approved by the Principal Investigator (PI) although
            colleagues may submit documents on their behalf.
          </p>
          <p>
            To submit a manuscript, access
            <a href="//plus.europepmc.org/">Europe PMC plus</a> and login.
            <a href="//plus.europepmc.org/user-guide">Detailed help guides</a>
            supporting the submission process can be found on Europe PMC plus.
          </p>
        </template>
        <template v-else-if="section.id === 'readingfeatures'">
          <h3 id="accessSavedAlerts">Saved alerts</h3>
          <h4 id="finding-alerts">Finding saved alerts</h4>
          <p>
            After you save an alert, you can access saved alerts from the
            <a href="/accounts/SavedAlerts">My account</a> page, in the
            drop-down in the top-right corner of the website.
          </p>
          <div>
            <img src="@/assets/my-account-link.png" alt="My account link" />
          </div>
          <h4 id="finding-searches">Finding saved searches</h4>
          <p>
            Queries saved as "Saved searches" prior to the "Saved alerts"
            feature, will be listed on the Saved alerts page, and you will have
            the option to convert them to alerts and set a frequency for
            updates.
          </p>
          <div>
            <img
              src="@/assets/convert-alert-link.png"
              alt="Convert alert link"
              class="smaller"
            />
          </div>
          <h4 id="what-to-do-from-saved-alerts">
            What you can do from the Saved alerts page
          </h4>
          <p>From the Saved alerts page, you can</p>
          <ul>
            <li>Create a new alert.</li>
            <li>Edit your alerts email to change where the emails are sent.</li>
            <li>
              Click on any saved alert to re-run the search and see all results.
            </li>
            <li>
              See the frequency that has been selected for each alert, or
              whether it's an inactive alert (When no frequency is selected.)
            </li>
            <li>Edit any saved alert.</li>
            <li>Delete any saved alert.</li>
            <li>
              Connect your accounts if you used different logins and saved
              alerts to different accounts.
            </li>
          </ul>
          <div>
            <img src="@/assets/saved-alert-page.png" alt="Saved alerts page" />
          </div>

          <h3 id="epmcaccounts">Europe PMC Accounts</h3>
          <p>
            With a Europe PMC account you can sign in to save search queries.
            Other features will be added in future.
          </p>
          <h4 id="signinEPMCAccount">How to sign into Europe PMC</h4>
          <p>
            You can sign into Europe PMC, to save your searches, with external
            services including ORCID, Twitter and Europe PMC Plus. Alternatively
            you can create a Europe PMC account.
          </p>
          <ul>
            <li>
              <strong>Sign in with ORCID</strong>
              <p>
                You will be taken to a page on the ORCID website where you can
                either enter your ORCID login details or create a new ORCID
                account. You will be redirected back to Europe PMC after signing
                in.
              </p>
            </li>
            <li>
              <strong>Sign in with Twitter</strong>
              <p>
                You will be taken to a page on the Twitter website where
                (providing you are already logged into Twitter via your web
                browser) you simply authorise Europe PMC to use your Twitter
                account. You will be redirected back to Europe PMC after
                authorisation.
              </p>
            </li>
            <li>
              <strong>Sign in with Europe PMC Plus</strong>
              <p>
                You can now sign in with a Europe PMC Plus account. You will be
                taken to the Plus website and redirected back to Europe PMC
                after signing in.
              </p>
            </li>
            <li>
              <strong>Sign in with Europe PMC</strong>
              <p>
                You can sign in with a Europe PMC account. This is not the same
                as the Europe PMC Plus account, which is used to submit
                manuscripts. You will need to register to create a new Europe
                PMC account. Find out how to
                <a href="#createEPMCAccount">create a Europe PMC account</a>.
              </p>
            </li>
          </ul>
          <h4 id="createEPMCAccount">Create a Europe PMC account</h4>
          <p>
            Click the 'create an account' link at the very top of the site
            header.
          </p>
          <div>
            <img
              src="@/assets/CreateAccountHeader.png"
              alt="Create account link"
            />
          </div>
          <p>
            You will be taken to a form where you can enter your name, email
            address a password to create a new Europe PMC account.
          </p>
          <p>
            After submitting the registration form you will receive an email
            with an account activation link. Once you have activated your
            account you will be able to access your first saved search query and
            save additional search queries.
          </p>
          <h4 id="activateEPMCAccount">
            I have created a Europe PMC account but I can't access my saved
            searches
          </h4>
          <p>
            You may not have activated your Europe PMC account. Please check
            your email for the activation link.
          </p>
          <p>
            You may have saved searches using another sign-in (eg. ORCID or
            Twitter). You can
            <a href="/accounts/ConnectAccounts">connect the accounts</a> to
            merge your saved searches into one account.
          </p>
          <h4 id="deleteAccount">How to delete your user account</h4>
          <p>
            Access the 'Manage account' link from your display name in the top
            right corner. The 'Delete account' option can be found in the left
            menu.
          </p>
        </template>
      </template>
    </static-page-layout>
  </div>
</template>
<style scoped lang="scss">
.fa-external-link-alt {
  margin-left: $base-unit;
}
.table-overflow {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
#count-graph {
  text-align: center;
  .column {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    width: 120px;
    width: 12rem;
    &.middle {
      width: 20rem;
    }
    .circle {
      display: inline-block;
      margin: 0.5rem;
      &.medium {
        width: 12rem;
        height: 12rem;
        border-radius: 6rem;
        font-size: 0.9em;
      }
      &.large {
        width: 20rem;
        height: 20rem;
        border-radius: 10rem;
        font-size: 1.2em;
      }
      &.small {
        width: 8rem;
        height: 8rem;
        border-radius: 4rem;
        font-size: 0.7em;
      }
      &.patents {
        background-color: #e9f4e4;
      }
      &.full-text {
        background-color: #d9e9f6;
      }
      &.abstracts {
        background-color: #c3e3b5;
      }
      &.nhs {
        background-color: #eef5f9;
      }
      &.preprints {
        background-color: #d5eacb;
        margin-right: -46px;
      }
      &.agricola {
        background-color: #eef5f9;
      }
      &::before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        height: 100%;
      }
      div {
        width: 90%;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
img {
  max-width: 100%;
  &.medium {
    width: $base-unit * 160;
  }
  &.small {
    width: $base-unit * 128;
  }
  &.smaller {
    width: $base-unit * 64;
  }
  &.smallest {
    width: $base-unit * 50;
  }
}
</style>
